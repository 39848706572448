export const TEAM_PERMISSION = 'Team';
export const TRIAGE_PERMISSION = 'Triage';
export const EMPLOYEE_TRIAGE = 'Employee Triage';
export const CLIENT_TRIAGE = 'Client Triage';
export const CLIENT_HANDBACK = 'Client Hand Back'
export const CLIENT_SWAP_VISIT = 'Client Swap Visit'
export const CLIENT_VISIT = 'Client Visits';
export const DASHBOARD_PER = 'Dashboards';
export const CLIENT_PERMISSION = 'Client';
export const CALENDER_PERMISSION = 'Calendar';
export const REPORT_PERMISSION = 'Reports';
export const INVOICE_PERMISSION = 'Invoicing';
export const FORM_BUILDER_PERMISSION = 'Form Builder';
export const FORM_BUILDER_CHOICES_PERMISSION = 'Form Builder Choices Settings';
export const FORM_BUILDER_CATEGORY_PERMISSION =
  'Form Builder Category Settings';
export const SETTINGS_PERMISSION = 'Settings';
export const FINANCE_PERMISSION = 'Finance';
export const CLIENT_SETTINGS = 'Client Settings';
export const ROLES_AND_PERMISSIONS_SETTINGS = 'Roles and permissions Settings';
export const COMPANY_SETTINGS = 'Company Setting';
export const TEAM_SETTINGS = 'Team Settings';
export const TEAM_SKILL = 'Skill';
export const TEAM_JOB_TITLE = 'Job Title';
export const TEAM_EMPLOYE_CATEGORY = 'Employment Category';
export const TEAM_EMPLOYE_TYPE = 'Employment Type';
export const TEAM_EMPLOYE_ABSENCE = 'mployee Absence';
export const TEAM_ABSENCE_OVERVIEW = 'Absence Overview';
export const TEAM_ABSENCE_HISTORY = 'Absence History';
export const TEAM_ABSENCE_TYPE = 'Absence Type';
export const TEAM_ABSENCE_REASON = 'Absence Reason';
export const TEAM_ABSENCE_INFO = 'Absence Informed Method Type';
export const TEAM_ABSENCE_PLANNED = 'Absence Planned';
export const TEAM_ABSENCE_PAID = 'Absence Paid';
export const TEAM_COMPENTECY = 'Competency Name';
export const TEAM_SHIFT = 'Regular Shift Type';
export const TEAM_BILLING_TERM = 'Billing Terms';
export const TEAM_BILLING_PATTERN = 'Billing Patterns';
export const TEAM_TRAINGING_TYPE = 'Training Type';
export const TEAM_ESTABLISHMENT_TYPE = 'Establishment Type';
export const TEAM_REGISTRATION_TYPE = 'Registration Type';
export const TEAM_ETHENCITY = 'Ethnicity';
export const TEAM_TITLE = 'Title';
export const TEAM_ANIMAL = 'Animal';

export const TEAM_VISA = 'Visa Status';
export const TEAM_REFERENCE_STATUS = 'Reference Status';
export const TEAM_APPLICANT_SOURCE = 'Applicant Source';
export const TEAM_LEAVER_REASON = 'Leaver Reason';
export const CALENDAR_SETTINGS = 'Calendar Settings';
export const USER_SETTINGS = 'Users Settings';
export const USER_SETTINGS_EXPORT = "Users Settings Export";
export const ROTA_SHIFTS_SETTINGS = 'Rota - Shifts';
export const SHIFT_PATTERN_SETTINGS = 'Shift - patterns';
export const CLIENT_VISIT_AUDIT = 'Visit Audit'
export const EVV_SETTINGS = 'EVV';
export const SCHEDULER_SETTINGS = 'Scheduler';
export const LIST_OF_CLIENT_SETTINGS = 'List of Clients';
export const LIST_OF_OFFER_VISIT = 'Offer Visit';
export const CLIENT_VISITS = 'Client Contracted Visits';
export const LIST_OF_EMPLOYEES_SETTINGS = 'List of Employees';
export const TEAM_ROUTE_MAP = 'Team Route Mapping';
export const WEB_PERMISSION = 'web';
export const WEB_READ_PERMISSION = 'web_read';
export const WEB_PERMISSION_ALL = 'web_all';
export const WEB_CREATE_PERMISSION = 'web_create';
export const WEB_DELETE_PERMISSION = 'web_delete';
export const WEB_UPDATE_PERMISSION = 'web_update';
export const SELF_WEB_READ_PERMISSION = 'self_web_read';
export const SELF_WEB_CREATE_PERMISSION = 'self_web_create';
export const SELF_WEB_DELETE_PERMISSION = 'self_web_delete';
export const SELF_WEB_UPDATE_PERMISSION = 'self_web_update';
export const ADMIN_EMAIL = 'admin@myhelpa.com';
export const CLIENT_CANCELLED_VISITS = 'Client Cancelled Visits';
export const SETTINGS_ALGO_SCORE = 'Algo Score';
export const SETTINGS_RATE_CARD = 'Rate Card';
export const REGULAR_VISITS = 'Regular Visits';
export const CLIENT_PERSONAL_DETAILS = 'Client Personal Details';
export const CLIENT_PREFRENCES = 'Client Preference';
export const CLIENT_DETAILS = 'Client Details';
export const CLIENT_DETAILS_HEALTH = 'Health Details';
export const CLIENT_SERVICE_CONTRACT = 'Client Service Contract';
export const CLIENT_PLANNED_TASK = 'Planned Task';
export const CLIENT_MEDICATION_PRESCRIPTION = 'Medication Prescriptions';
export const VISIT_CARE_NOTES = 'Visit Care Notes';
export const CLIENT_IMPORTANT_CONTACT = 'Important Contact';
export const CLIENT_VISIT_CARE_NOTES = 'Reports Client Care Notes';
export const CLIENT_NOTES = 'Client Notes';
export const CLIENT_MEDICATION_NOTES = 'Medication Notes';
export const CLIENT_MEDICATION_TAB = 'Client Medication';

export const CLIENT_CARE_NOTES = 'Care Notes';
export const CLIENT_CARE_PLAN = 'Client Care Plans';
export const CLIENT_CARE_DIARY = 'Care Diary';
export const CLIENT_FILE = 'Client File';
export const CLIENT_TASK = 'Client Task';
export const CLIENT_FORMS = 'Client Form Builder';

export const CLIENT_CONTRACTED_VISITS = 'Client Contracted Visits';
export const CLIENT_CANELLED_VISITS = 'Client Cancelled Visits';
export const CLIENT_OFFER_VISITS = 'Offer Visit';
export const CLIENT_BASIC_INFORMATION = 'Client Basic Information';
export const CLIENT_CONTACT = 'Client Contact';
export const CLIENT_ADDRESS = 'Client Address';
export const CLIENT_INVITE_USER = 'Client invite user';
export const CLIENT_REFERRALS = 'Client Referrals';
export const CLIENT_FUNDER_CONTACT = 'Funder Contact';
export const CLIENT_FUNDER = 'Funder';
export const CLIENT_SERVICE_VISITS = 'Service Visits';
export const CLIENT_SERVICE = 'Service';
export const CLIENT_SERVICE_ADDRESS = 'Service Address';
export const CLIENT_FUNDER_BILL_RATE_CARD = 'Funder Bill Rate Card';
export const CLIENT_FUNDER_PAY_RATE_CARD = 'Funder Pay Rate Card';
export const CLIENT_DEMOGRAPHICS = 'Client Demographics';
export const CLIENT_PREFERENCES = 'Client Preferences';
export const CLIENT_INCOMPATIBILITIES = 'Incompatibilities';
export const CLIENT_HEALTH_DETAILS = 'Client Health Details';
export const CLIENT_NEXT_OF_KIN = 'Client Next of Kin';
export const CLIENT_CARE_CONTACTS = 'Client Care Contacts';
export const CLIENT_MEDICAL_INFO = 'Medical Information';
export const CLIENT_ALLERGIES_INTOLERANCES = 'Allergies and Intolerances';
export const CLIENT_VACCINATIONS = 'Vaccinations';
export const CLIENT_MEDICATION = 'Medication';
export const CLIENT_BODY_PART = 'Client Body Part'
export const CLIENT_EXPENSES = 'Client Expenses'
export const EMPLOYEE_PERSONAL_DETAILS = 'Employee Personal Details';
export const EMPLOYEE_ABSENCE = 'Employee Absence';
export const EMPLOYEE_PERSONAL_BASIC_DETAILS = 'Employee Basic Details';
export const REVERT_CLOCKED = 'Revert Clocked';
export const EMPLOYEE_ABSENCE_OVERVIEW = 'Absence Overview';
export const EMPLOYEE_ABSENCE_HISTORY = 'Absence History';
export const EMPLOYEE_PERSONAL_ADDRESS = 'Employee Address';
export const EMPLOYEE_PERSONAL_CONTACT = 'Employee Contact';
export const EMPLOYEE_PERSONAL_INVITE_USER = 'Employee invite user';
export const EMPLOYEE_JOB_DETAILS = 'Employee Job Details';
export const EMPLOYEE_DETAILS = 'Employee Details';
export const COMPLIANCE = 'Compliance';
export const SKILLS = 'Skills';
export const NOTES = 'Employee Notes';
export const REGULAR_SHIFTS = 'Regular Shifts';
export const EMPLOYEE_FILE = 'Employee File';
export const EMPLOYEE_EXPENSES = 'Employee Expensess';
export const EMPLOYEE_PREFERENCES = 'Employee Preferences';
export const EMPLOYEMENT_DETAILS = 'Employment Details';
export const PAY_RATE_CARD = 'Pay Rate Card';
export const PROBATION = 'Probation';
export const RECRUITMENT = 'Recruitment';
export const NOTICE_AND_TERMINATION = 'Notice and Termination';
export const INCOMPATIBILITIES = 'Incompatibilities';
export const EMPLOYEE_DEMOGRAPHICS = 'Employee Demographics';
export const EMPLOYEE_NEXT_OF_KIN = 'Employee Next of Kin';
export const EMPLOYEE_SKILLS_AND_QUALIFICATIONS =
  'Employee Skills and Qualifications';
export const MEDICAL_DETAILS = 'Medical Details';
export const ALLERGIES_AND_INTOLERANCE = 'Allergies And Intolerances';
export const VISA_RIGHT_TO_WORK = 'Visa - Right to Work';
export const DISCLOSURE_BARRING = 'Disclosure & Barring (DBS)';
export const VACCINATION = 'Vaccination';
export const DRIVER = 'Driver';
export const VEHICLE_DETAILS = 'Vehicle Details';
export const EMPLOYEE_ID = 'Employee Id';
export const COMPILANCE_REFERENCES = 'Compilance References';
export const SKILLS_TRAINING = 'Skills & training';
export const COMPETENCIES = 'Competencies';
export const QUALIFICATION_APPRENTICESHIPS = 'Qualification & Apprenticeships';
export const SCHEDULER_DAILY_VIEW = 'Scheduler Daily View';
export const SCHEDULER_WEEKLY_VIEW = 'Scheduler Weekly View';
export const SCHEDULER_CLIENT_VIEW = 'Scheduler Client View';
export const SCHEDULER_TEAM_VIEW = 'Scheduler Team View';
export const SCHEDULER_RUN_VIEW = 'Scheduler Run View';
export const SCHEDULER_LIST_VIEW = 'Scheduler List View';
export const SCHEDULER_LOCATION_VIEW = 'Scheduler Location View';
export const ROTA_ADD_AVAILABLE = 'Add Available';
export const ROTA_ADD_UNAVAILABLE = 'Add Unavailable';
export const ROTA_ASSIGN_SHIFT = 'Assign Shift';
export const EVV_INDEX = 'EVV';
export const COMPANY_LICENSE_SETTINGS = 'Company Licenses Settings';
export const BRANCH_SETTINGS = 'Branch Settings';
export const RUN_ROUTE = 'Run Route';
export const PRIORITY_SETTINGS = 'Priority Settings';
export const COMPANYS = 'Company Settings';
export const COMPANY_BRANCHES = 'branches';
export const ABSENCE_LIST = 'Employee Absence';
export const RATE_CARD = 'Rate Card';
export const PUBLIC_HOLIDAY = 'Public Holiday';
export const FUNDER_DETAILS = 'Funder Details';
export const FINANCE_BILL_SUMMARY = 'Bill Summary';
export const FINANCE_BILL_COMPARE = 'Bill Compare';
export const FINANCE_BILL_DETAILS = 'Bill Details';
export const FINANCE_PAY_SUMMARY = 'Pay Summary';
export const FINANCE_PAY_FINROLL = 'Pay Roll';
export const FINANCE_PAY_DETAILS = 'Pay Details';
export const FINANCE_BILLING_TAB = 'Billing';
export const FINANCE_PAYROLL_TAB = 'Payroll';
export const REPORT_TEAM_TAB = 'Reports Team';
export const REPORT_KPI_TAB = 'Report Kpi';
export const REPORT_CLIENT_TAB = 'Reports Client';
export const REPORT_TEAM_PD = 'Reports Employee Personal Details';
export const REPORT_TEAM_JD = 'Reports Job Details';
export const REPORT_TEAM_NOK = 'Reports NOK';
export const REPORT_TEAM_JOB_RATE_CARD = 'Reports job rate card';
export const REPORT_TEAM_MEDICAL = 'Reports Medical Condition';
export const REPORT_TEAM_ALLERGIES = 'Reports Employee Allergies';
export const REPORT_TEAM_VISIT_CARE = 'Reports Team Allergies';
export const REPORT_TEAM_PREFERRED_CLIENT = 'Reports Preferred Client';
export const REPORT_TEAM_AVOID_CLIENT = 'Reports Avoid Client';
export const REPORT_TEAM_AVOID_EMPLOYEE = 'Reports Avoid Employee';
export const REPORT_TEAM_AVOID_POSTAL = 'Reports Avoid Postal Area';
export const REPORT_TEAM_AVOID_ANIMAL = 'Reports Avoid Animal';
export const REPORT_EMPLOYEE_ROTA_EMAIL = 'Reports Rota';
export const REPORT_CLIENT_ROTA_EMAIL = 'Client Email';
export const REPORT_AVOID_RUN = 'Reports Avoid Run';
export const REPORT_PREFERRED_RUN = 'Reports Preferred Run';
export const REPORT_VISIT_CARE = 'Reports Client Care Notes';
export const REPORT_TEAM_SKILL_TRAINING = 'Reports Skill and Training';
export const REPORT_TEAM_COMPETENCY = 'Reports Competency';
export const REPORT_TEAM_QUA_APP = 'Reports Qualification and Appreciation';
export const REPORT_TEAM_TRAINING = 'Reports Training';
export const REPORT_TEAM_REFERENCES = 'Reports References';
export const REPORT_TEAM_EMPLOYEE_VISA = 'Reports Employee Visa';
export const REPORT_TEAM_EMPLOYEE_DBS = 'Reports Employee Dbs';
export const REPORT_TEAM_VACCINATION = 'Reports Vaccination';
export const REPORT_TEAM_DRIVER = 'Reports Driver';
export const REPORT_TEAM_EMPLOYEE_ID = 'Reports Employee Id';
export const REPORT_TEAM_EXPENSE = 'Reports Employee Expense';
export const REPORT_CLIENT_PD = 'Reports Client Personal Details';
export const REPORT_CLIENT_PREFERRED_EMPLOYEE =
  'Reports Client Preferred Employee';
export const REPORT_CLIENT_AVOID_EMPLOYEE = 'Reports Client Avoid Employee';
export const REPORT_CLIENT_COMPETENCY = 'Reports Client Competency';
export const REPORT_CLIENT_SKILLS = 'Reports Client Skills';
export const REPORT_CLIENT_HEALTH = 'Reports Client Health';
export const REPORT_CLIENT_TASK = 'Reports Client Task';
export const REPORT_CLIENT_SERVICE_CONTRACT = 'Reports Client Service Contract';
// 
export const REPORT_SERVICE_CONTRACT = 'Reports Service Contract';
export const REPORT_CLIENT_MEDICAL = 'Reports Client Medical';
export const REPORT_CLIENT_ALLERGIES = 'Reports Client Allergies';
export const REPORT_CLIENT_CARE_CONTACT = 'Reports Client Care Contact';
export const REPORT_VISIT_CARE_CONTACT = 'Reports Visit Care Contact';
export const REPORT_CLIENT_KPI = 'Reports Client Kpi';

export const REPORT_CLIENT_COMPLIANCE = 'Reports Visit - Compliance Kpi';
export const REPORT_CLIENT_QUALITY = 'Reports Quality Assurance Kpi';
export const REPORT_FINANCE_KPI = 'Reports Finance Kpi'
export const REPORT_TEAM_KPI = 'Reports Team Kpi';
export const REPORT_TASKS = 'task';


export const BRAD_FORD_PERMISSION = 'Bradford Factor Settings';

//Email Settings
export const EMAIL_SETTINGS = "Email Settings"
//notification 
export const NOTIFICATION_ICON = 'Notification';
export const NOTIFICATION_TAB = 'Notifications';
export const NOTIFICATION_SETTING = 'Notification Settings';

//Visit pop up 
export const VISIT_POP_UP = "Visit Pop-up";
export const VISIT_POPUP = 'Visit Pop-up';

export const VISIT_RATE = "Visit Rate";
export const VISIT_SCORE = "Visit Score";
export const VISIT_EMPLOYEE_SUGGESTION = "Visit Employee Suggestion";
export const VISIT_AUDIT_HISTORY = "Visit Audit History";
export const VISIT_MEDICATION = "Visit Medication";
export const VISIT_BODY_MAP = "Visit Body Map";
export const VISIT_TASK = "Visit Task";
export const VISIT_EXPENSE = 'Visit Expense';

//Competency Matrix key
export const TEAM_COMPETENCY_MATRIX = 'Competency Matrix'
export const TEAM_SKILL_MATRIX = 'Skill Matrix'

// Team < Employee Expense 
export const EXPENSE_DETAILS = 'Expense Details'
export const EXPENSE_AUTHORIZATION = 'Expense Authorization'
export const EMPLOYEE_AVERAGE_HOURS = 'Employee Work Detail'

// Setting QA tasks 
export const SETTING_HR_TASK = 'Setting QA-Task'
export const HR_TASKS = "QA Tasks"
export const EVENT_HR_TASTS = 'Event QA Tasks'
export const REPEATING_HR_TASKS = 'Repeating QA Tasks'

// Client hr task 
export const CLIENT_HR_TASK = 'Client QA Task'

//Employee Hr Task
export const EMPLOYEE_HR_TASK = 'Employee QA Task';
export const EMPLOYEE_SKILL_MATRIX = 'Employee Skill Matrix';
export const EMPLOYEE_COMPETENCY_MATRIX = 'Employee Competency Matrix';
export const EMPLOYEE_HR_TASK_MATRIX = 'Employee QA Task Matrix';
export const SETTINGS_FINANCE = 'Settings Finance';
export const BILLING_SETTINGS = "Billing Settings";
export const NON_COMMUTE_TRAVEL_BILL = "Non Commute Travel Bill";
export const PAYROLL_SETTINGS = "Payroll Settings";
export const NON_COMMUTE_TRAVEL = 'Non Commute Travel';
export const SKILL_CATEGORY = "Skill Category";
export const CLIENT_QA_TASK_MATRIX = 'Client QA Task Matrix';
export const LEAVE_ACCRUAL_SETTINGS = 'Leave Accrual Settings';
export const SETTINGS_ABSENCE_OVERVIEW = 'Settings Absence Overview'
export const SETTINGS_TAX = 'Settings Tax';

//Location 
export const LOCATION_PERMISSION = 'Location'
export const LIST_OF_LOCATIONS = 'List of Locations'
export const LOCATION_DETAILS = 'Location Personal Details'
export const LOCATION_BASIC_INFORMATION = 'Location Basic Information'
export const LOCATION_ADDRESS = 'Location Address'
export const LOCATION_CONTACT = 'Location Contact'
export const LOCATION_ACCESS_BRANCH = 'Location Access Branch'
//Employee care notes
export const EMPLOYEE_CARE_NOTES = 'Employee Care Notes';

export const VISIT_CARE_NOTES_DETAILS = "Visit Care Notes Details";
export const VISIT_CARE_NOTES_REVIEWERS = "Visit Care Notes Reviewers";
export const EXPORT_VISIT_CARE_NOTES = "Export Visit Care Notes";
//Branch Default Privacy
export const BRANCH_DEFAULT_PRIVACY = 'Branch Default Privacy';
export const ASSESSMENT = 'Assessment';

export const LOCATION_SERVICE_CONTRACT = "Location Service Contract";
export const LOCATION_SERVICE = "Location Service";
export const LOCATION_SERVICE_ADDRESS = "Location Service Address";
export const LOCATION_FUNDER = "Location Funder";
export const LOCATION_FUNDER_CONTACT = "Location Funder Contact";
export const LOCATION_FUNDER_BILL_RATE_CARD = "Location Funder Bill Rate Card";
export const LOCATION_FUNDER_PAY_RATE_CARD = "Location Funder Pay Rate Card";
export const LOCATION_FUNDER_TRAVEL_PAY_RATE_CARD = "Location Funder Travel Pay Rate Card";
export const LOCATION_SERVICE_VISITS = "Location Service Visits";

export const CLIENT_KPI = "Client Kpi"
export const VISIT_COMPLIANCE_KPI = "Visit - Compliance Kpi"
export const TEAM_KPI_PERMISSION = "Team Kpi"
export const QUALITY_ASSURANCE_KPI = "Quality Assurance Kpi"
export const MEDICATION_KPI = "Medication Kpi"
export const INSIGHTS = "Insights"
export const DASHBOARD_AUDITS = "Dashboard Audits"


export const BILL_RATES = "Bill Rates"
export const PAY_RATES = "Pay Rates"
export const ON_CALL = "On Call Rates"
export const OFFICE_PERMISSION = "Office Rates"
export const ABSENCE = "Absence Rates"
export const NLW_RLW = "NLW/RLW"
export const TRAVEL_RATES = "Travel Rates"
export const OTHERS_RATES = "Others Rates"
export const SHIFT_RATES = "Shift Rates"
export const WAIT_RATES = "Wait Rates"


export const CALENDAR_TYPE = "Calendar Type";

export const EMPLOYEE_RATES = 'Employee Rate Cards';
export const EMPLOYEE_PAY_RATES = "Employee Pay Rates";
export const EMPLOYEE_ON_CALL_RATES = "Employee On Call Rates";
export const EMPLOYEE_OFFICE_RATES = "Employee Office Rates";
export const EMPLOYEE_SHIFT_RATES = "Employee Shift Rates";
export const EMPLOYEE_WAIT_RATES = "Employee Wait Rates";
export const EMPLOYEE_ABSENCE_RATES = "Employee Absence Rates";
export const EMPLOYEE_TRAVEL_RATES = "Employee Travel Rates";

export const JOB_DEPARTMENT = "Job Department";


export const VISIT_REMINDER = 'Visit Reminder';
export const GROUP_AREA = 'Group Area';

//About me 
export const CLIENT_ABOUT_ME = "Client About Me";
export const ABOUT_ME = "About Me";
export const  INTEREST = "Interest";
export const LIFE_HISTORY = "Life History";
export const DAILY_ROUTINE = "Daily Routine";
export const SUPPORT_NEED = "Support Need";
export const IDENTIFICATION_CHARACTERISTICS = "Identification Characteristics";

//Risk assessmment
export const CLIENT_RISK_ASSESSMENT = 'Client Risk Assessment'
export const ESV_PERMISSION = 'ESV';
export const COPY_VISITS = 'Copy Visits'
export const LOCK_VISIT_PERMISSION = "Lock Visit";
export const UNLOCK_VISIT_PERMISSION = "Unlock Visit";
export const EMPLOYEE_FORM_BUILDER = "Employee Form Builder";
export const REGULAR_SHIFT = 'Regular Shift'


export const CRON_JOB_SHIFT_REPORTS  = 'Cron Job Shift Reports';
export const CRON_JOB_MEDICATION_REPORTS = 'Cron Job Medication Reports';
export const  CRON_JOB_VISIT_REPORTS= 'Cron Job Visit Reports';
export const CRON_JOB_DELETED_VISIT_REPORTS = 'Cron Job Deleted Visit Reports';
export const SESSION_BRANCH_EXPIRY_TIME = "Session Branch Expire Time";






















